<template>
  <div class="stepsdemo-content">
    <Card>
      <template #content>
        <div class="p-fluid p-d-flex" :style="{ width: '100%' }">
          <admin-data-table
            v-if="this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()"
            :adminrefactoListAnswers="adminrefactoListAnswers"
            :invalidQuestionIds="invalidQuestionIds"
          ></admin-data-table>
          <data-table-model
            v-else
            :refactoListAnswers="refactoListAnswers"
            :invalidQuestionIds="invalidQuestionIds"
          ></data-table-model>
        </div>
      </template>
      <template #footer>
        <p             v-if="
            (!(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) &&
            (getPlanList?.data[0].status !== 'F_CREATED') && (getPlanList?.data[0].status !== 'F_REJECTED'))">
          <small :style="{ color: '#ef3636', fontStyle: 'italic' }"
            >Toutes les valeurs de cette section sont requises (*).
          </small>
        </p>
        <div class="p-fluid p-d-flex p-jc-start" :style="{ gap: '1em', 'margin-top': '2em' }">
          <Button
            label="Retour"
            @click="gotoTrainingStep()"
            icon="pi pi-angle-left"
            iconPos="left"
            :style="{ width: '20%' }"
          />
          <Button
            label="Enregistrer les informations"
            v-if="
            (!(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) &&
            (getPlanList?.data[0].status !== 'F_VALIDATED') && (getPlanList?.data[0].status !== 'F_SUBMITTED'))"
            @click="saveAnswers()"
            :style="{ width: '40%' }"
            icon="pi pi-check"
            iconPos="right"
          />
          <Button
            label="Retourner pour correction"
            @click="reject(getToReturn,this.getPlanList?.data[0]?.id)"
            v-if="((this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole())
            && (getPlanList?.data[0].status !== 'F_VALIDATED') && (getPlanList?.data[0].status !== 'F_REJECTED'))"
            :style="{ width: '20%' }"
            class="p-button-danger"
            icon="pi pi-check"
            iconPos="right"
          />
          <!-- {{ getPlanList?.data[0].status }} -->
          <!-- v-if="(getPlanList?.data[0].status != 'F_VALIDATED') && (getPlanList?.data[0].status != 'F_SUBMITTED')" -->
          <Button
            label="Valider"
            @click="validate(this.getPlanList?.data[0]?.id)"
            v-if="
            ((this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) &&
            (getPlanList?.data[0].status !== 'F_VALIDATED') && (getPlanList?.data[0].status !== 'F_REJECTED'))"
            :style="{ width: '20%' }"
            class="p-button-success"
            icon="pi pi-check"
            iconPos="right"
          />
          <Button
          v-if="
            (!(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) &&
            (getPlanList?.data[0].status !== 'F_VALIDATED') && (getPlanList?.data[0].status !== 'F_SUBMITTED'))"
            label="Soumettre"
            @click="openModal()"
            :style="{ width: '20%' }"
            class="p-button-success"
            icon="pi pi-check"
            iconPos="right"
          />
        </div>
      </template>
    </Card>
  </div>
</template>
  <script>
import dataTableModel from '@/components/local-content/component/data-table-model';
import adminDataTable from '@/components/local-content/component/admin-data-table';
import localContentSupplyMixin from '@/mixins/local-content-supply-mixin';
export default {
  mixins: [localContentSupplyMixin],
  data() {
    return {
      suppliesQuestionsListMock: {},
      suppliesAnswersListMock: {},
      refactoListAnswers: [],

      invalidQuestionIds: [],
      adminrefactoListAnswers: [],
    };
  },
  components: {
    dataTableModel,
    adminDataTable,
  },
  // computed:{
  //     ...mapGetters({
  //         getPlanList: 'localContent/getPlanList',
  //         getSuppliesQuestionsListMock:'localContent/getSuppliesQuestionsListMock',
  //         getSuppliesAnswersListMock:'localContent/getSuppliesAnswersListMock'
  //     }),
  // },
  // mounted(){
  //     this.fetchAllSupplies();
  // },
  // watch: {
  //     getSuppliesQuestionsListMock (newSuppliesQuestions, oldSuppliesQuestions) {
  //         this.suppliesQuestionsListMock = newSuppliesQuestions;
  //         console.log(oldSuppliesQuestions);
  //     },
  //     getSuppliesAnswersListMock(newSuppliesAnswers, oldSuppliesAnswers) {
  //         this.suppliesAnswersListMock = newSuppliesAnswers;
  //         this.setRefactoList();
  //         console.log(oldSuppliesAnswers);
  //     }
  // },
  // methods: {
  //     ...mapActions({
  //         fetchAllSupplies:'localContent/fetchAllSupplies',
  //         fetchPlanSummary:'localContent/fetchPlanSummary',
  //         submitAnswers: 'localContent/submitAnswers',
  //         onSubmissionToggle: 'dialog/loadPlanSummary',
  //     }),
  //     toggleJobsComment(event, slot) {
  //   console.log(this.$refs);
  //   if (slot.questionId) {
  //     this.$refs['comment_' + slot.questionId].toggle(event);
  //   } else {
  //     this.$refs['comment_' + slot.id].toggle(event);
  //   }
  // },
  // setRefactoList() {
  //   this.refactoListAnswers = [];
  //   this.suppliesQuestionsListMock.forEach((element) => {
  //     let hasAnswer = [];
  //     hasAnswer = this.suppliesAnswersListMock.filter((e) => {
  //       return e.questionId === element.id;
  //     })?.[0];

  //     //BUILD LIST OF ANSWERS FROM QUESTIONS LIST
  //     this.refactoListAnswers.push({
  //       answers: {
  //         questionId: element.id,
  //         ...(hasAnswer?.localContentPlanId && { id: hasAnswer.id }),
  //         forecast: hasAnswer?.forecast || 0,
  //         real: hasAnswer?.real || 0,
  //         comment: hasAnswer?.comment || '',
  //       },
  //       title: element.title,
  //       year: 2023,
  //     });
  //   });
  // },
  // saveAnswers() {
  //   let Obj = {
  //     answers: [],
  //     year: 2023,
  //   };
  //   this.refactoListAnswers.forEach((element) => {
  //     Obj.answers.push(element.answers);
  //   });
  //   console.log(JSON.stringify(Obj));
  //   this.submitAnswers(Obj).then((e) => {
  //     this.$toast.add({
  //       severity: 'success',
  //       summary: 'Mise à jour',
  //       detail: 'Mise à jour effectuée avec success',
  //       life: 7000,
  //     });
  //     console.log(e);
  //   });
  // },
  //   closeEditModal(){
  //       this.displayEditModal = false;
  //   },
  //   openEditModal(data){
  //       this.displayEditModal = true;
  //       this.displayEditModalData = data
  //   },
  //   gotoTrainingStep() {
  //   this.$router.push('/local-content/step/training');
  // },
  // async openModal() {
  //     await this.fetchPlanSummary();
  //     this.onSubmissionToggle();
  // //   this.$router.push('/local-content/step/supply');
  // },
  //   formatCurrency(value) {
  //     return value.toLocaleString('fr-FR', { style: 'currency', currency: 'XOF' });
  //   },
  // },
};
</script>
  <style scoped>
.p-fluid .p-inputnumber {
  max-width: 19em;
  margin-left: 0.5em;
}
.p-card {
  box-shadow: none;
}
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}
</style>