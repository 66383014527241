import { mapActions, mapGetters } from 'vuex';
import LoggedUserMixin from '@/mixins/logged-user';
const localContentStepperMixin = {
    computed: {
        ...mapGetters({
            getToReturn: 'localContent/getToReturn',
            getTrainingsInTable: 'localContent/getTrainingsInTable',
            getPlanInfos: 'localContent/getPlanInfos',
            getPlanList: 'localContent/getPlanList',
            getSuppliesQuestionsListMock: 'localContent/getSuppliesQuestionsListMock',
            getSuppliesAnswersListMock: 'localContent/getSuppliesAnswersListMock'
        }),
    },
    mixins:[LoggedUserMixin],
    watch: {
        getPlanList: {
            handler(val) {
                if (val) {
                    this.handleFetchPlanInfos();
                }
            },
            deep: true
        },
        getPlanInfos: {
            handler(val) {
                if (val) {
                    this.setRefactoList();
                }
            },
            deep: true
        },
        getSuppliesQuestionsListMock(newJobsQuestions, oldJobsQuestions) {
            this.jobsQuestionsListMock = newJobsQuestions;
            console.log(oldJobsQuestions);
        },
        getSuppliesAnswersListMock(newJobsAnswers, oldJobsAnswers) {
            console.log(oldJobsAnswers);
            this.jobsAnswersListMock = newJobsAnswers;
            this.setRefactoList();
            console.log('ref', this.refactoListAnswers);
        },
    },
    async mounted() {
        if(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) {this.fetchPlans({params: `companyName=${encodeURIComponent(this.$route.params.companyName.toLowerCase())}`})}else{this.fetchAllSupplies()}
        // console.log(this.getPlanList?.data[0]?.id);
    },
    methods: {
        ...mapActions({
            selectToReturn: 'localContent/selectToReturn',
            fetchPlans: 'localContent/fetchPlans',
            fetchAllSupplies: 'localContent/fetchAllSupplies',
            submitAnswers: 'localContent/submitAnswers',
            fetchPlanInfos: 'localContent/fetchPlanInfos',
            fetchPlanSummary:'localContent/fetchPlanSummary',
            onSubmissionToggle: 'dialog/loadPlanSummary',
            validatePlan:'localContent/validatePlan',
            returnPlan:'localContent/returnPlan'
        }),
        handleFetchPlanInfos() {
            // alert(this.getPlanList?.data[0]?.id);
            this.fetchPlanInfos({ params: this.getPlanList?.data[0]?.id });
        },
        setRefactoList() {
                this.refactoListAnswers = [];
                this.adminrefactoListAnswers = [];

                let planInfosAnswers= this.getPlanInfos?.answers;
                let planInfosQuestions= this.getPlanInfos?.questions;
                // console.log(this.getPlanInfos,",,,,",planInfosQuestions)
                //BUILD LIST OF ANSWERS FROM QUESTIONS LIST
                if(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) {
                    planInfosQuestions.forEach((element) => {
                        let hasAnswer = [];
        
                        hasAnswer = planInfosAnswers.filter((e) => {
                            return e.questionId === element.id;
                        })?.[0];

                        if(element.heading === "SUPPLY"){
                            this.adminrefactoListAnswers.push({
                                answers: {
                                    unit:element.unit,
                                    ...(hasAnswer?.localContentPlanId && { validityStatus: hasAnswer.validityStatus }),
                                    questionId: element.id,
                                    ...(hasAnswer?.localContentPlanId && { id: hasAnswer.id }),
                                    forecast: (hasAnswer?.forecast >= 0)? hasAnswer?.forecast : null,
                                    real: (hasAnswer?.real >= 0)? hasAnswer?.real : null,
                                    comment: hasAnswer?.comment || '',
                                },
                                title: element.title,
                                year: 2023,
                            });
                        }

                        
                    });
                }else{
                    this.jobsQuestionsListMock.forEach((element) => {

                        let hasAnswer = [];
        
                        hasAnswer = this.jobsAnswersListMock.filter((e) => {
                            return e.questionId === element.id;
                        })?.[0];

                    this.refactoListAnswers.push({
                        answers: {
                            unit:element.unit,
                            ...(hasAnswer?.localContentPlanId && { validityStatus: hasAnswer.validityStatus }),
                            questionId: element.id,
                            ...(hasAnswer?.localContentPlanId && { id: hasAnswer.id }),
                            forecast: (hasAnswer?.forecast >= 0)? hasAnswer?.forecast : null,
                            real: (hasAnswer?.real >= 0)? hasAnswer?.real : null,
                            comment: hasAnswer?.comment || '',
                        },
                        title: element.title,
                        year: 2023,
                    });
                });
                }
        },
        saveAnswers() {
            let Obj = {
                answers: [],
                year: 2023,
            };
            this.refactoListAnswers.forEach((element) => {
                Obj.answers.push(element.answers);
            });

            // if (!this.isAllValuesDefined()) {
            //     this.$toast.add({
            //         severity: 'error',
            //         summary: 'Mise à jour',
            //         detail: 'Mise à jour effectuée non efféctués',
            //         life: 7000,
            //     });
            // } else {
            //     this.submitAnswers(Obj).then((e) => {
            //         this.$toast.add({
            //             severity: 'success',
            //             summary: 'Mise à jour',
            //             detail: 'Mise à jour effectuée avec success',
            //             life: 7000,
            //         });
            //         console.log(e);
            //     });
            // }

                            this.submitAnswers(Obj).then((e) => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Mise à jour',
                        detail: 'Mise à jour effectuée avec success',
                        life: 7000,
                    });
                    console.log(e);
                });

        },
        isAllValuesDefined() {
            let invalidQId = this.refactoListAnswers.filter(e => { return e.answers.forecast === null }).map(function (e) {
                return e.answers.questionId;
            });
            console.log((invalidQId.length === 0));

            if (invalidQId.length === 0) {
                this.invalidQuestionIds = []
            } else {
                this.invalidQuestionIds = invalidQId;
            }

            if (this.invalidQuestionIds.length === 0) return true;
            return false;
        },
            openEditModal(data){
        this.displayEditModal = true;
        this.displayEditModalData = data
    },
        openModal() {
            if (!this.isAllValuesDefined()) return;

            this.saveAnswers();
            this.fetchPlanSummary();
            this.onSubmissionToggle();
            this.$router.push('/local-content/step/supply');
    },
    saveEditModal() {
        const train = JSON.stringify({
          training: {
            id: this.training.id,
            title: this.training.title,
            nbHours: this.training.nbHours,
          },
          year: 2023,
        });
        this.submitTraining(train).then((e) => {
          this.closeNewTraining();
          console.log(e);
        });
      },
      submitNewTraining() {
        const train = JSON.stringify({
          training: {
            title: this.training.title,
            nbHours: this.training.nbHours,
          },
          year: 2023,
        });
        this.submitTraining(train).then((e) => {
          this.closeNewTraining();
          console.log(e);
        });
      },
      confirmDeleteTraining(id) {
        this.$confirm.require({
          message: 'Voulez-vous supprimer la formation ?',
          header: 'Confirmation de suppression',
          icon: 'pi pi-info-circle',
          acceptLabel: 'Oui',
          rejectLabel: 'Non',
          acceptClass: 'p-button-danger',
          accept: () => {
            // this.$toast.add({severity:'info', summary:'Confirmed', detail:'Record deleted', life: 3000});
            this.deleteTraining({ trainingId: id });
          },
          reject: () => {
            // this.$toast.add({severity:'info', summary:'Operation annul', detail:'You have rejected', life: 3000});
          },
        });
      },
      closeNewTraining() {
        this.displayEditModal = false;
  
        this.training.id = 0;
        this.training.nbHours = 0;
        this.training.title = '';
      },
          gotoTrainingStep() {
            this.$router.push(`/local-content/step/${this.getPlanList?.data[0]?.entreprise.name}/training`);
  },
  validate(planId){
    console.log(planId);
    this.$confirm.require({
        message: 'Voulez-vous valider le plan de contenu local ?',
        header: 'Confirmation de validation',
        icon: 'pi pi-info-circle',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        acceptClass: 'p-button-danger',
        accept: () => {
            this.validatePlan(planId).then(e=>{
                this.$toast.add({
                  severity: 'success',
                  summary: 'Validation du plan de contenu local',
                  detail: 'Le plan de contenu local a été validé avec succès',
                  life: 7000,
                });
                console.log(e);
              }).catch(e=>{
                this.$toast.add({
                  severity: 'error',
                  summary: 'Validation du plan de contenu local',
                  detail: "Une erreur s'est produite avec la validation du le plan de contenu local",
                  life: 7000,
                });
                console.log(e);
              })
        },
        reject: () => {
          // this.$toast.add({severity:'info', summary:'Operation annul', detail:'You have rejected', life: 3000});
        },
      });
  },
  reject(data,planId){
    console.log(planId);
    this.$confirm.require({
        message: 'Voulez-vous retourner pour correction le plan de contenu local ?',
        header: 'Confirmation de retour pour correction',
        icon: 'pi pi-info-circle',
        acceptClass: 'p-button-success',
        acceptLabel: 'Oui',
        rejectLabel: 'Non',
        acceptClass: 'p-button-danger',
        accept: () => {
            this.returnPlan({ toReturnList: data,lcpId: planId }).then(e=>{
                this.$toast.add({
                  severity: 'success',
                  summary: 'Retour pour correction du plan de contenu local',
                  detail: 'Le plan de contenu local a été retournée pour correction avec success',
                  life: 7000,
                });
                console.log(e);
              }).catch(e=>{
                this.$toast.add({
                  severity: 'error',
                  summary: 'Retour pour correction du plan de contenu local',
                  detail: "Une erreur s'est produite avec le retour pour correction du le plan de contenu local",
                  life: 7000,
                });
                console.log(e);
              })
        },
        reject: () => {
          // this.$toast.add({severity:'info', summary:'Operation annul', detail:'You have rejected', life: 3000});
        },
      });
  },
        async gotoSalaryStep() {
            if (!this.isAllValuesDefined()) return;
            // console.log(this.invalidQuestionIds == []);
            if(this.isValidatorPublicationRole() || this.isAdminRole() || this.isSimpleAdminRole()) {
                console.log(this.getPlanList?.data[0]);
            }else{   
                
                this.saveAnswers();
            }
            this.$router.push(`/local-content/step/${this.getPlanList?.data[0]?.entreprise.name}/salary`);
        },

        closeEditModal() {
            this.displayEditModal = false;
        },
        getAnswer(id) {
            const data = this.refactoListAnswers.filter((e) => e.answers.questionId === id)[0]?.answers;
            return data;
        },
        formatCurrency(value) {
            return value.toLocaleString('fr-FR', { style: 'currency', currency: 'XOF' });
        },
    },
};

export default localContentStepperMixin;
